<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- Brand logo-->
        <b-link
          :to="{ path: '/' }"
          class="brand-logo"
        >
          <logo />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <b-card-title class="mb-1">
          {{ $t('auth.Reset Password') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.Your new password must be different from previously used passwords') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="resetForm"
          #default="{invalid}"
        >
          <b-form
            id="reset"
            class="auth-login-form mt-2"
            @submit.prevent="submit"
          >
            <!--phone-->
            <b-form-group
              :label="$t('auth.Phone')"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('auth.Phone')"
                vid="phone"
                rules="required"
              >
                <VuePhoneNumberInput
                  id="phone"
                  v-model="phone.number"
                  :error="phone.valid!==true"
                  name="phone"
                  valid-color="#28C76F"
                  :required="true"
                  :translations="{
                    countrySelectorLabel: $t('Country code'),
                    countrySelectorError: $t('Choose a country'),
                    phoneNumberLabel: $t('Phone Number'),
                    example: $t('Example:')
                  }"
                  :only-countries="countriesList"
                  :default-country-code="countryCode"
                  @update="onPhoneUpdate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <div v-if="phone.valid">
                  <small>{{ $t('auth.We might ask you to enter a verification code that we send to this phone' ) }}</small>
                </div>

              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label-for="password"
              :label="$t('auth.New Password')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('auth.New Password')"
                rules="min:8|required|password:@passwordConfirmation"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="········"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group
              label-for="password-confirmation"
              :label="$t('auth.Password Confirmation')"
            >
              <validation-provider
                v-slot="{ errors }"
                name="passwordConfirmation"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-confirmation"
                    v-model="passwordConfirmation"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    placeholder="········"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--OTP-->
            <otp-form
              :phone="getFormattedPhone()"
              :verifying-required="verifyingRequired"
              :new-user-phone="newUserPhone"
              :reset-password="true"
              :password="password"
              :password-confirmation="passwordConfirmation"
              @phoneVerified="onPhoneVerified()"
            />

            <!-- checkbox -->
            <!--              <b-form-group>-->
            <!--                <b-form-checkbox-->
            <!--                  id="remember-me"-->
            <!--                  v-model="submitting"-->
            <!--                  name="checkbox-1"-->
            <!--                >-->
            <!--                  <small>{{ $t('auth.Remember Me') }}</small>-->
            <!--                </b-form-checkbox>-->
            <!--              </b-form-group>-->

            <!-- submit buttons -->
            <b-button
              v-if="!verifyingRequired"
              type="submit"
              variant="primary"
              block
              :disabled="invalid"
            >
              <span v-if="submitting">
                <b-spinner small />
                {{ $t('auth.Set New Password') }}
              </span>
              <span v-if="!submitting">
                {{ $t('auth.Set New Password') }}
              </span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('auth.Back to login') }}
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password -->
    </div>
  </div>

</template>

<script>
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'

import {
  digits, min, required,
} from '@validations'

import { $themeConfig } from '@themeConfig'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/mixins/notification/toasts'
import { numbersOnly } from '@/mixins/functions'
import i18n from '@/libs/i18n'
import {
  BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCardTitle, BCardText, BSpinner, BCard,
} from 'bootstrap-vue'

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { locales } from '@/mixins/options'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: i18n.t('auth.password_confirmation_does_not_match'),
})

export default {
  components: {
    Logo,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BCard,
    // validations
    ValidationProvider,
    ValidationObserver,

    VuePhoneNumberInput,
    otpForm: () => import('./components/OtpForm.vue'),
  },
  mixins: [togglePasswordVisibility, showToast, numbersOnly],
  data() {
    return {
      phone: {
        number: '',
        valid: false,
        country: undefined,
      },
      submitting: false,
      verifyingRequired: false,
      userPhone: null,
      newUserPhone: null,
      password: '',
      passwordConfirmation: '',

      // validation
      required,
      min,
      digits,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    countryCode() {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode || 'UA'
    },
  },
  methods: {
    onPhoneVerified() {
      this.verifyingRequired = false
      this.submitting = false
      this.redirectToLoginPage()
    },
    redirectToLoginPage() {
      const customSuccessText = 'auth.Please Sign In using a new password'
      const title = this.$t('auth.Password updated')
      this.$router.push('/login')
      this.showToast({ status: 200 }, 'Account', title, customSuccessText, 10000)
    },
    onPhoneUpdate({
      countryCode, isValid, phoneNumber, countryCallingCode, formattedNumber,
    }) {
      this.phone.valid = isValid
      if (isValid) {
        this.userPhone = formattedNumber
        this.submitting = false
        this.verifyingRequired = false
      }
    },
    getFormattedPhone() {
      return this.userPhone ? this.userPhone.replace(/\+/g, '') : ''
    },
    submit() {
      this.$refs.resetForm.validate().then(success => {
        if (success) {
          this.resetPassword()
        }
      })
    },
    resetPassword() {
      this.submitting = true
      // Display OTP form
      this.newUserPhone = this.getFormattedPhone()
      this.verifyingRequired = true
    },
  },
  setup() {
    const { appName } = $themeConfig.app
    const { countriesList } = locales()
    return {
      appName,
      countriesList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
