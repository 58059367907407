var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":{ path: '/' }}},[_c('logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('auth.Reset Password'))+" 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('auth.Your new password must be different from previously used passwords'))+" ")]),_c('validation-observer',{ref:"resetForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"id":"reset"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('auth.Phone'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.Phone'),"vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"id":"phone","error":_vm.phone.valid!==true,"name":"phone","valid-color":"#28C76F","required":true,"translations":{
                  countrySelectorLabel: _vm.$t('Country code'),
                  countrySelectorError: _vm.$t('Choose a country'),
                  phoneNumberLabel: _vm.$t('Phone Number'),
                  example: _vm.$t('Example:')
                },"only-countries":_vm.countriesList,"default-country-code":_vm.countryCode},on:{"update":_vm.onPhoneUpdate},model:{value:(_vm.phone.number),callback:function ($$v) {_vm.$set(_vm.phone, "number", $$v)},expression:"phone.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.phone.valid)?_c('div',[_c('small',[_vm._v(_vm._s(_vm.$t('auth.We might ask you to enter a verification code that we send to this phone' )))])]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"password","label":_vm.$t('auth.New Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.New Password'),"rules":"min:8|required|password:@passwordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"········"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"password-confirmation","label":_vm.$t('auth.Password Confirmation')}},[_c('validation-provider',{attrs:{"name":"passwordConfirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password-confirmation","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"placeholder":"········"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('otp-form',{attrs:{"phone":_vm.getFormattedPhone(),"verifying-required":_vm.verifyingRequired,"new-user-phone":_vm.newUserPhone,"reset-password":true,"password":_vm.password,"password-confirmation":_vm.passwordConfirmation},on:{"phoneVerified":function($event){return _vm.onPhoneVerified()}}}),(!_vm.verifyingRequired)?_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[(_vm.submitting)?_c('span',[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.$t('auth.Set New Password'))+" ")],1):_vm._e(),(!_vm.submitting)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.Set New Password'))+" ")]):_vm._e()]):_vm._e()],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('auth.Back to login'))+" ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }